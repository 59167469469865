import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'

const LoginPage = lazy(() => import('features/Authentication/presenter/pages/Login'))
const LoginOtpPage = lazy(() => import('features/Authentication/presenter/pages/LoginOtp'))
const SessionExpiredPage = lazy(() => import('features/Authentication/presenter/pages/SessionExpired'))
const LogoutPage = lazy(() => import('features/Authentication/presenter/pages/Logout'))
const ReportPage = lazy(() => import('features/Reports/presenter/pages/NominappReport'))
const ReportDummyPage = lazy(() => import('features/Reports/presenter/pages/NominappReportDummy'))
const ReportXPage = lazy(() => import('features/Reports/presenter/pages/NominappReportX'))
const Simulation = lazy(() => import('features/Simulator/presenter/pages/Simulate'))
const SendRequest = lazy(() => import('features/InformationPages/presenter/pages/SendRequest'))
const RequestInProgress = lazy(() => import('features/InformationPages/presenter/pages/RequestInProgress'))
const PreapprovalExpired = lazy(() => import('features/InformationPages/presenter/pages/PreapprovalExpired'))
const PreapprovalAvailable = lazy(() => import('features/InformationPages/presenter/pages/PreapprovalAvailable'))

const DisableUser = lazy(() => import('features/DisableUsers'))
const BillingCharge = lazy(() => import('features/BillingCharge'))
const UploadCustomer = lazy(() => import('features/UploadCustomer'))
const HistoricInstallment = lazy(() => import('features/HistoricInstallment'))

export const Routes = () => (
  <Suspense fallback={null}>
    <Switch>
      <Route path='/' exact>
        <LoginOtpPage />
      </Route>
      <Route path='/login' exact>
        <LoginOtpPage />
      </Route>
      <Route path='/loginusr_name' exact>
        <LoginPage />
      </Route>
      <Route path='/loginotp' exact>
        <LoginOtpPage />
      </Route>
      <Route path='/session-expired' exact>
        <SessionExpiredPage />
      </Route>
      <Route path='/logout' exact>
        <LogoutPage />
      </Route>
      <Route path='/private-payroll/report' exact>
        <ReportPage />
      </Route>
      <Route path='/private-payroll/dummy' exact>
        <ReportDummyPage />
      </Route>
      <Route path='/private-payroll/ReportXPage' exact>
        <ReportXPage />
      </Route>
      <Route path='/simulator' exact>
        <Simulation />
      </Route>
      <Route path='/send-request' exact>
        <SendRequest />
      </Route>
      <Route path='/request-in-progress' exact>
        <RequestInProgress />
      </Route>
      <Route path='/preapproval-expired' exact>
        <PreapprovalExpired />
      </Route>
      <Route path='/preapproval-available' exact>
        <PreapprovalAvailable />
      </Route>
      <Route path='/disableUser' exact>
        <DisableUser />
      </Route>
      <Route path='/billingCharge' exact>
        <BillingCharge />
      </Route>
      <Route path='/uploadCustomer' exact>
        <UploadCustomer />
      </Route>
      <Route path='/historicInstallment' exact>
        <HistoricInstallment />
      </Route>
    </Switch>
  </Suspense>
)
