import './public-path'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'

const render = (props) => {
  const { container } = props

  ReactDOM.render(
    <App />,
    container ? container.querySelector('#root') : document.querySelector('#root')
  );
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({})
}

export const bootstrap = async () => {
  //console.log('[MF] private-payroll app bootstraped');
}

export const mount = async (props) => {
  //console.log('[MF] private-payroll app mounted, props: ', props);
  render(props)
}

export const unmount = async (props) => {
  const { container } = props
  ReactDOM.unmountComponentAtNode(container ? container.querySelector('#root') : document.querySelector('#root'))
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
