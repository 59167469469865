export const options = [
    {
        id: 0,
        title: "Perfil de la empresa",
        nick: "PefilEmpresa",
        icon: "CompanyProfile.png",
        role: "admin",
        subOptions: [
            {
                id: 0,
                name: "Histórico adelantos",
                nick: "historicInstallment",
                icon: "Historic.png",
                role: "admin",
                activeIcon: "HistoricActive.png"
            },
            {
                id: 1,
                name: "Inactiva Usuarios",
                nick: "disableUser",
                icon: "DisableUser.png",
                role: "admin",
                activeIcon: "DisableUserActive.png"
            },
            {
                id: 2,
                name: "Tus cuentas de cobro",
                nick: "billingCharge",
                icon: "billingCharge.png",
                role: "admin",
                activeIcon: "billingChargeActive.png"
            },
            {
                id: 3,
                name: "Importar Base",
                nick: "uploadCustomer",
                icon: "uploadCustomer.png",
                role: "admin",
                activeIcon: "uploadCustomerActive.png"
            },
        ]
    },
    {
        id: 1,
        title: "Perfil del Cliente",
        nick: "PerfilCliente",
        icon: "CustomerProfile.png",
        role: "all",
        subOptions: [
            {
                id: 0,
                name: "Solicita tu Adelanto",
                nick: "simulator",
                icon: "GetPayrollAdvance.png",
                role: "all",
                activeIcon: "GetPayrollAdvanceActive.png"
            },
            {
                id: 1,
                name: "Solicita tu Seguro",
                nick: "insurance",
                icon: "insurance.png",
                role: "all",
                activeIcon: "insurance.png"
            },
        ]
    },
    {
        id: 2,
        title: "Cerrar sesión",
        nick: "CloseSession",
        icon: "general.png",
        role: "all",
        subOptions: []
    }
]