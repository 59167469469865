import React, { useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import { getCompanyLogo } from 'core/utils/lookAndFeel.js'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import { LookAndFeel } from 'core/utils/lookAndFeel.js'

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import IconDashboard from '@material-ui/icons/Dashboard'
import IconShoppingCart from '@material-ui/icons/ShoppingCart'
import IconPeople from '@material-ui/icons/People'
import IconBarChart from '@material-ui/icons/BarChart'
import IconLibraryBooks from '@material-ui/icons/LibraryBooks'
import { Switch } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { options } from './constants/options'



import { useHistory } from "react-router-dom"

const AppMenu = () => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [selectedSubOption, setSelectedSubOption] = useState('')

    const history = useHistory()

    const iconRoute = 'menu/';

    const handleClick = (nick) => {
        if (nick === "CloseSession") {
            document.location.replace("/login")
        }
    }

    const handleSuboptClick = (nick) => {
        if (nick === 'insurance') {
            window.open('https://club.avista.co/seguros-an/', '_blank');
        }
        setSelectedSubOption(nick)
    }

    function isSubMenuVisible(nick, role) {

        const companyIdentificationValue = sessionStorage.getItem("companyIdentification")
        const isAdmin = sessionStorage.getItem("isAdmin")
        //console.log(" is admin ", isAdmin)
        if (nick === 'insurance') {
            if (companyIdentificationValue === '901422313' ||
                companyIdentificationValue === '900480786' ||
                companyIdentificationValue === '900871479') {
                return '';
            } else {
                return 'none';
            }
        }

        return isMenuVisible(role);
    }

    function isMenuVisible(role) {
        //console.log(" role ", role)
        const isAdmin = sessionStorage.getItem("isAdmin")
        if (isAdmin === true || isAdmin === "true") {
            return '';
        }

        if (role === 'all') {
            return '';
        }

        return 'none';
    }

    return (
        <List component="nav" className={classes.appMenu} disablePadding>
            {options.map((option) => (
                <div key={option.id}>
                    <ListItem
                        button
                        key={option.id}
                        onClick={() => handleClick(option.nick)}
                        style={{
                            display: isMenuVisible(option.role),
                            color: selectedSubOption === option.nick ? LookAndFeel().menu.selected : LookAndFeel().menu.primary,
                        }}
                        className={classes.menuItem}>
                        <ListItemIcon className={classes.menuItemIcon}>
                            <img alt="option_icon" src={iconRoute + option.icon} />
                        </ListItemIcon>
                        <ListItemText primary={option.title} primaryTypographyProps={{ fontSize: '15px' }} />

                    </ListItem>
                    <Divider />
                    <List component="div" disablePadding>
                        {
                            option.subOptions.map(subOption =>
                            (
                                <ListItem
                                    component={Link} to={'/' + subOption.nick}
                                    button
                                    onClick={() => handleSuboptClick(subOption.nick)}
                                    key={subOption.id}
                                    enabled={false}
                                    //className={classes.subMenuItem}
                                    style={{
                                        width: drawerWidth,
                                        padding: '0px 20px',
                                        margin: '5px auto',
                                        marginLeft: '20px',
                                        display: isSubMenuVisible(subOption.nick, subOption.role),
                                        color: selectedSubOption === subOption.nick ? LookAndFeel().menu.selected : LookAndFeel().menu.primary,
                                        borderTopRightRadius: '30px',
                                        borderBottomRightRadius: '30px'
                                    }}
                                >
                                    <ListItemIcon className={classes.menuItemIcon}>
                                        <img alt="option_icon" src={selectedSubOption === subOption.nick ? iconRoute + subOption.activeIcon : iconRoute + subOption.icon} />
                                    </ListItemIcon>
                                    <ListItemText primary={subOption.name} primaryTypographyProps={{ fontSize: '15px' }} />
                                </ListItem>
                            )
                            )
                        }
                    </List>
                </div>
            )

            )}

        </List>
    )
}

const drawerWidth = 240

const useStyles = makeStyles(theme =>
    createStyles({
        appMenu: {
            width: '100%',
        },
        navList: {
            width: drawerWidth,
        },
        menuItem: {
            width: drawerWidth,
            marginLeft: '0px',
        },
        menuItemIcon: {
            color: '#97c05c',
        },
        subMenuItem: {
            width: drawerWidth,
            padding: '0px 20px',
            margin: '5px auto',
            marginLeft: '20px',
            borderTopRightRadius: '30px',
            borderBottomRightRadius: '30px'
        },
    }),
)

export default AppMenu
