import { THEME_AVISTA, THEME_AVANTO, THEME_NOMINAPP, THEME_LINXE } from 'core/theme'
import avistaLogo from 'main/assets/avista_icon.svg'
import avantoLogo from 'main/assets/avanto_icon.svg'
import nominappLogo from 'main/assets/nominapp_icon.svg'
import linxeLogo from 'main/assets/linxe_icon.png'

import avistaImage from 'main/assets/companyLogo/avista.svg'
import avantoImage from 'main/assets/companyLogo/avanto.svg'
import nominappImage from 'main/assets/companyLogo/nominapp.svg'
import linxeImage from 'main/assets/companyLogo/linxe.png'

const urlAvistaLocal = 'Alocalhost:3000'
const urlAvantoLocal = 'Blocalhost:3000'
const urlNominappLocal = 'Clocalhost:3000'
const urlLinxeLocal = 'localhost:3000'

const urlAvistaQa = 'qa-libranza-privada.avistapp.com'
const urlAvantoQa = 'qa-avanto-libranza-privada.avistapp.com'
const urlNominappQa = 'qa-nominapp-libranza-privada.avistapp.com'
const urlLinxeQa = 'qa-linxe-libranza-privada.avistapp.com'

const urlAvistaProd = 'libranza-privada.avistapp.com'
const urlAvantoProd = 'nominaya.avanto.com.co'
const urlNominappProd = 'libranza-privada-nominapp.avistapp.com'
const urlLinxeProd = 'adelanta.linxe.com'

const host = window.location.host

export const LookAndFeel = () => {
  //console.log(host)
  switch (host) {
    case urlAvistaLocal:
    case urlAvistaQa:
    case urlAvistaProd:
      return THEME_AVISTA
    case urlAvantoLocal:
    case urlAvantoQa:
    case urlAvantoProd:
      return THEME_AVANTO
    case urlNominappLocal:
    case urlNominappQa:
    case urlNominappProd:
      return THEME_NOMINAPP
    case urlLinxeLocal:
    case urlLinxeQa:
    case urlLinxeProd:
      return THEME_LINXE
    default:
      return THEME_AVISTA
  }
}
export const getCompanyLogo = () => {
  switch (host) {
    case urlAvistaLocal:
    case urlAvistaQa:
    case urlAvistaProd:
      return avistaLogo
    case urlAvantoLocal:
    case urlAvantoQa:
    case urlAvantoProd:
      return avantoLogo
    case urlNominappLocal:
    case urlNominappQa:
    case urlNominappProd:
      return nominappLogo
    case urlLinxeLocal:
    case urlLinxeQa:
    case urlLinxeProd:
      return linxeLogo
    default:
      return avistaLogo
  }
}

export const getCompanyImage = () => {
  switch (host) {
    case urlAvistaLocal:
    case urlAvistaQa:
    case urlAvistaProd:
      return avistaImage
    case urlAvantoLocal:
    case urlAvantoQa:
    case urlAvantoProd:
      return avantoImage
    case urlNominappLocal:
    case urlNominappQa:
    case urlNominappProd:
      return nominappImage
    case urlLinxeLocal:
    case urlLinxeQa:
    case urlLinxeProd:
      return linxeImage
    default:
      return avistaImage
  }
}


export const getSidebarIcons = () => {
  switch (host) {
    case 'avista':
      return 'Salmon'
    case 'linkalianza':
      return 'Orange'
    default:
      return 'Salmon'
  }
}

export const getFormsIcons = () => {
  switch (host) {
    case 'avista':
      return 'Salmon'
    case 'linkalianza':
      return 'Orange'
    default:
      return 'Salmon'
  }
}
