import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { BrowserRouter } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Routes } from "./main/routes"
import Box from '@mui/material/Box';
import { Flex } from '@rebass/grid'
import Drawer from '@mui/material/Drawer';
import AuthContextProvider from "./main/context/AuthContext"
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import SideMenu from './main/Menu/SideMenu';
import avistaLogo from 'main/assets/avista_icon.svg'
import Toolbar from '@mui/material/Toolbar';
import { LookAndFeel } from 'core/utils/lookAndFeel.js'
import { getCompanyLogo } from 'core/utils/lookAndFeel.js'
import { Button } from '@material-ui/core';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#FFFFFF'
    }
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },
});

const drawerWidth = 240;
//const showMenu = true;

const useStyles = makeStyles(theme =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#97c05c',
    },
  }),
)

const handleClickLogo = (nick) => {

  document.location.replace("/menu-backoffice")

}

function App() {
  const [showMenu, setShowMenu] = useState(true)
  const [isLogin, setIsLogin] = useState(false)
  const history = useHistory()

  const handleLogOut = async () => {
    document.location.replace("/login")
  }

  const handleGetIsUserAdmin = (_) => {
    //console.log("Is admin: ", sessionStorage.getItem('isAdmin'));

    setShowMenu(true);

    //console.log(window.location.pathname)
    if (window.location.pathname === "/login" || window.location.pathname === "/") {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }

  }

  useEffect(() => {
    handleGetIsUserAdmin()
  }, [])

  const classes = useStyles();
  return (
    <BrowserRouter basename={window.__POWERED_BY_QIANKUN__ ? '/private-payroll' : '/'}>
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <Box sx={{ display: showMenu ? 'flex' : '' }}>
            <CssBaseline />
            <AppBar
              style={{ background: showMenu ? '#2E3B55' : 'transparent', display: isLogin ? 'none' : '' }}
              position="fixed"
              sx={{ width: `calc(100% - ${showMenu ? drawerWidth : 0}px)`, ml: `${showMenu ? drawerWidth : 0}px` }}
            >
              <Toolbar >
                <Box width={[1]} ml={20}
                  style={{ display: showMenu || isLogin ? 'none' : '' }} >
                  <Flex justifyContent='center'>
                    <img className={classes.avatar} alt="Adelanto logo" src={getCompanyLogo()} />
                  </Flex>
                </Box>
                <Box width={[1 / 9]} mr={10}>
                  <Button
                    style={{ display: showMenu || isLogin ? 'none' : '' }}
                    //endIcon={<OutdoorGrill />}
                    onClick={handleLogOut}>
                    Cerrar Sesión
                  </Button>
                </Box>
              </Toolbar>
            </AppBar>
            <Drawer
              sx={{
                width: drawerWidth,
                display: showMenu && !isLogin ? null : 'none',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                },
              }}
              variant="permanent"
              anchor="left"
            >
              <img className={classes.avatar}
                alt="Avista logo"
                onClick={() => handleClickLogo()}
                src={getCompanyLogo()}
                style={{
                  paddingTop: 15,
                  paddingLeft: 20,
                  paddingRight: 30,
                  paddingBottom: 30
                }}
              />

              <SideMenu />
            </Drawer>
            <Routes />

          </Box>

        </AuthContextProvider>
      </ThemeProvider>
    </BrowserRouter >
  );
}

export default App;
