import React, { createContext, useState } from 'react'

export const AuthContext = createContext()

const AuthContextProvider = (props) => {
  const [isAuthenticated, setAuthenticated] = useState(false)

  const setToogleAuth = () => setAuthenticated(!isAuthenticated)

  return (
    <AuthContext.Provider value={{ isAuthenticated, setToogleAuth }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
