export const THEME = {
  breakpoints: ['48em', '62em', '75em'],
  spaces: [0, 7.5, 15, 30, 45, 60],
  header: {
    height: '64px'
  },
  drawer: {
    width: 300
  },
  footer: {
    height: '35px'
  },
  content: {},
  shared: {
    docsBackground: '#5B5B5B',
    bodyBackground: '#ffffff',
    logo: '#005776'
  },
  fontFamily: [
    'Poppins',
    'sans-serif'
  ].join(','),
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(',')
  },
  buttons: {
    primary: '#D0006F',
    secondary: '#000000',
    disabled: '#A0A0A0',
    text: '#ffffff',
    plain: '#ffffff',
    back: '#00A9CE',
    alternativeText: '#3D3D3D'
  },
  icon: {
    primary: '#D0006F',
    secondary: '#000000',
    disabled: '#3D3D3D',
    hover: '#D0006F'
  },
  menu: {
    primary: '#7d7979',
    selected: '#D0006F',
    secondary: '#000000',
    disabled: '#3D3D3D',
    hover: '#D0006F'
  },
  range: {
    primary: '#DF1995'
  },
  sidebar: {
    background: '#ffffff',
    color: '#3d3d3d'
  }
}

export const THEME_AVISTA = {
  breakpoints: ['48em', '62em', '75em'],
  spaces: [0, 7.5, 15, 30, 45, 60],
  header: {
    height: '64px'
  },
  drawer: {
    width: 300
  },
  footer: {
    height: '35px'
  },
  content: {},
  shared: {
    docsBackground: '#5B5B5B',
    bodyBackground: '#ff383f',
    logo: '#005776'
  },
  fontFamily: [
    'Poppins',
    'sans-serif'
  ].join(','),
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(',')
  },
  buttons: {
    primary: '#D0006F',
    secondary: '#000000',
    disabled: '#e77fb7',
    text: '#ffffff',
    plain: '#ffffff',
    back: '#00A9CE',
    alternativeText: '#3D3D3D',
    hover: '#c6057e'
  },
  icon: {
    primary: '#D0006F',
    secondary: '#000000',
    disabled: '#3D3D3D',
    hover: '#D0006F'
  },
  menu: {
    primary: '#7d7979',
    selected: '#D0006F',
    secondary: '#000000',
    disabled: '#3D3D3D',
    hover: '#D0006F'
  },
  text: {
    primary: '#D0006F',
    secondary: '#000000',
    disabled: '#FDE7E0',
    hover: '#D0006F',
    base: '#7d7979'
  },
  radio: {
    primary: '#D0006F',
    secondary: '#000000',
    disabled: '#FDE7E0',
    hover: '#D0006F'
  },
  check: {
    primary: '#D0006F',
    secondary: '#000000',
    disabled: '#FDE7E0',
    hover: '#FC4C02'
  },
  select: {
    primary: '#D0006F',
    secondary: '#000000',
    disabled: '#FDE7E0',
    hover: '#D0006F'
  },
  switch: {
    primary: '#D0006F',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#D0006F'
  },
  slider: {
    primary: '#D0006F',
    secondary: '#D0006F',
    disabled: '#D9D9D9',
  },
  card: {
    primary: '#f6cce2',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#D0006F'
  },
  range: {
    primary: '#DF1995'
  },
  sidebar: {
    background: '#ffffff',
    color: '#3d3d3d'
  }
}

export const THEME_AVANTO = {
  breakpoints: ['48em', '62em', '75em'],
  spaces: [0, 7.5, 15, 30, 45, 60],
  header: {
    height: '64px'
  },
  drawer: {
    width: 300
  },
  footer: {
    height: '35px'
  },
  content: {},
  shared: {
    docsBackground: '#5B5B5B',
    bodyBackground: '#ffffff',
    logo: '#005776'
  },
  fontFamily: [
    'Poppins',
    'sans-serif'
  ].join(','),
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(',')
  },
  buttons: {
    primary: '#BFBFBF',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    text: '#0f111d',
    plain: '#ffffff',
    back: '#00A9CE',
    alternativeText: '#3D3D3D',
    hover: '#FC4C02'
  },
  icon: {
    primary: '#164194',
    secondary: '#000000',
    disabled: '#fabfad',
    hover: '#164194'
  },
  menu: {
    primary: '#7d7979',
    selected: '#164194',
    secondary: '#000000',
    disabled: '#3D3D3D',
    hover: '#D0006F'
  },
  text: {
    primary: '#164194',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#FC4C02',
    base: '#7d7979'
  },
  radio: {
    primary: '#FC4C02',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#FC4C02'
  },
  check: {
    primary: '#FC4C02',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#FC4C02'
  },
  switch: {
    primary: '#FC4C02',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#FC4C02'
  },
  slider: {
    primary: '#164194',
    secondary: '#54CEF3',
    disabled: '#D9D9D9',
  },
  card: {
    primary: '#FDE7E0',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#D0006F'
  },
  range: {
    primary: '#DF1995'
  },
  sidebar: {
    background: '#ffffff',
    color: '#3d3d3d'
  }
}

export const THEME_NOMINAPP = {
  breakpoints: ['48em', '62em', '75em'],
  spaces: [0, 7.5, 15, 30, 45, 60],
  header: {
    height: '64px'
  },
  drawer: {
    width: 300
  },
  footer: {
    height: '35px'
  },
  content: {},
  shared: {
    docsBackground: '#5B5B5B',
    bodyBackground: '#ff383f',
    logo: '#005776'
  },
  fontFamily: [
    'Poppins',
    'sans-serif'
  ].join(','),
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(',')
  },
  buttons: {
    primary: '#622CFF',
    secondary: '#000000',
    disabled: '#e77fb7',
    text: '#ffffff',
    plain: '#ffffff',
    back: '#00A9CE',
    alternativeText: '#3D3D3D',
    hover: '#c6057e'
  },
  icon: {
    primary: '#622CFF',
    secondary: '#000000',
    disabled: '#3D3D3D',
    hover: '#622CFF'
  },
  menu: {
    primary: '#7d7979',
    selected: '#622CFF',
    secondary: '#000000',
    disabled: '#3D3D3D',
    hover: '#D0006F'
  },
  text: {
    primary: '#622CFF',
    secondary: '#000000',
    disabled: '#FDE7E0',
    hover: '#622CFF',
    base: '#7d7979'
  },
  radio: {
    primary: '#622CFF',
    secondary: '#000000',
    disabled: '#FDE7E0',
    hover: '#622CFF'
  },
  check: {
    primary: '#622CFF',
    secondary: '#000000',
    disabled: '#FDE7E0',
    hover: '#FC4C02'
  },
  select: {
    primary: '#622CFF',
    secondary: '#000000',
    disabled: '#FDE7E0',
    hover: '#622CFF'
  },
  switch: {
    primary: '#622CFF',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#622CFF'
  },
  slider: {
    primary: '#622CFF',
    secondary: '#622CFF',
    disabled: '#D9D9D9',
  },
  card: {
    primary: '#f6cce2',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#622CFF'
  },
  range: {
    primary: '#DF1995'
  },
  sidebar: {
    background: '#ffffff',
    color: '#3d3d3d'
  }
}

export const THEME_LINXE = {
  breakpoints: ['48em', '62em', '75em'],
  spaces: [0, 7.5, 15, 30, 45, 60],
  header: {
    height: '64px'
  },
  drawer: {
    width: 300
  },
  footer: {
    height: '35px'
  },
  content: {},
  shared: {
    docsBackground: '#5B5B5B',
    bodyBackground: '#ffffff',
    logo: '#005776'
  },
  fontFamily: [
    'Poppins',
    'sans-serif'
  ].join(','),
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(',')
  },
  buttons: {
    primary: '#0F4E57',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    text: '#EEF0F3',
    plain: '#ffffff',
    back: '#00A9CE',
    alternativeText: '#3D3D3D',
    hover: '#FC4C02'
  },
  icon: {
    primary: '#0F4E57',
    secondary: '#A71229',
    disabled: '#fabfad',
    hover: '#164194'
  },
  menu: {
    primary: '#0F4E57',
    selected: '#A71229',
    secondary: '#000000',
    disabled: '#3D3D3D',
    hover: '#D0006F'
  },
  text: {
    primary: '#0F4E57',
    secondary: '#A71229',
    disabled: '#e0e0e0e6',
    hover: '#FC4C02',
    base: '#7d7979'
  },
  radio: {
    primary: '#FC4C02',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#FC4C02'
  },
  check: {
    primary: '#FC4C02',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#FC4C02'
  },
  switch: {
    primary: '#FC4C02',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#FC4C02'
  },
  slider: {
    primary: '#0F4E57',
    secondary: '#437A84',
    disabled: '#D9D9D9',
  },
  card: {
    primary: '#FDE7E0',
    secondary: '#000000',
    disabled: '#e0e0e0e6',
    hover: '#D0006F'
  },
  range: {
    primary: '#DF1995'
  },
  sidebar: {
    background: '#ffffff',
    color: '#3d3d3d'
  }
}